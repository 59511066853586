<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap22Maps"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 22: Maps" image-name="maps.jpg" image-alt="Maps"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is a map?</p></li>
<li><p>What is a key, a value?</p></li>
<li><p>How to create a map.</p></li>
<li><p>How to insert an entry in a map.</p></li>
<li><p>How to retrieve an entry from a map.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Map type</p></li>
<li><p>Key-Value pair</p></li>
<li><p>Map entry</p></li>
<li><p>Hash table</p></li>
<li><p>Time complexity</p></li>
</ul>
<div id="why-do-we-need-maps" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Why do we need maps? <a href="#why-do-we-need-maps"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In this section, we will detail how maps are working. But first, let’s take some time to understand why this data structure can be useful with an example :</p>
<div id="with-a-slice" class="anchor"></div>
<h4 data-number="3.0.0.1"><span class="header-section-number">3.0.0.1</span> With a slice <a href="#with-a-slice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >// maps/without-maps/main.go
package main

import &quot;fmt&quot;

type testScore struct {
    studentName string
    score       uint8
}

func main() {
    results := []testScore{
        {&quot;John Doe&quot;, 20},
        {&quot;Patrick Indexing&quot;, 15},
        //...
        //...
        {&quot;Bob Ferring&quot;, 7},
        {&quot;Claire Novalingua&quot;, 8},
    }
    fmt.Println(results)
}</code></pre>
<p>We have a type struct <span v-highlightjs><code class="go" v-pre style="display: inline">testScore</code></span> and a slice <span v-highlightjs><code class="go" v-pre style="display: inline">results</code></span> composed of <span v-highlightjs><code class="go" v-pre style="display: inline">testScore</code></span>s elements. Now let’s imagine that I want to retrieve the score of the student named <strong>Claire Novalingua.</strong></p>
<p>We are using a slice we have to iterate over each element to find the item searched :</p>
<pre v-highlightjs><code class="go" v-pre >for _, result := range results {
    if result.studentName == &quot;Claire Novalingua&quot; {
        fmt.Println(&quot;Score Found:&quot;, result.score)
    }
}</code></pre>
<p>Why is this solution not optimal?</p>
<ul>
<li><p>We have to iterate potentially over all elements of the slice. Imagine that your slice contains thousands of elements! The impact on performance can be important.</p></li>
<li><p>The code written is not short. We use a for loop range and a nested comparison. Those five lines are not easy to read.</p></li>
</ul>
<div id="what-is-a-map" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> What is a map? <a href="#what-is-a-map"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A map is an unordered collection of elements of type T that are indexed by unique keys of type U<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>.</p>
<div id="example" class="anchor"></div>
<h4 data-number="4.0.0.1"><span class="header-section-number">4.0.0.1</span> Example : <a href="#example"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<figure>
<b-img :src="require('@/assets/images/map_definition.png')" alt="Map example[fig:Map-example]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Map example<span id="fig:Map-example" label="fig:Map-example">[fig:Map-example]</span></figcaption>
</figure>
<p>In the previous figure (<a href="#fig:Map-example" data-reference-type="ref" data-reference="fig:Map-example">1</a>) we have a map representing the football world cup winners by year. Here the key is the <strong>year</strong> (which is an <span v-highlightjs><code class="go" v-pre style="display: inline">uint8</code></span>) and the values that represent the country name of the winner (<span v-highlightjs><code class="go" v-pre style="display: inline">string</code></span>). The map type is denoted :</p>
<pre v-highlightjs><code class="go" v-pre >map[uint8]string</code></pre>
<p>An element of a map is called a “map entry”. It’s also usually named a key-value pair.</p>
<div id="general-definition" class="anchor"></div>
<h4 data-number="4.0.0.2"><span class="header-section-number">4.0.0.2</span> General definition <a href="#general-definition"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >map[keyType]elementType</code></pre>
<p>With a map, you can do the following operations :</p>
<ul>
<li><p>store a value with a specific key</p></li>
<li><p>delete a value stored with a specific key</p></li>
<li><p>retrieve a value stored with a specific key</p></li>
</ul>
<p>Let’s take another example; a dictionary can be stored using a map. In a dictionary, we have definitions of words that are stored. In this case, the definitions are the elements, and the words represent the keys. When you use a dictionary, you search for a specific word to get its definition. We never look into a dictionary by definition. This type of lookup might cost you a lot of time because definitions are not indexed. We can keep this analogy for maps. We always make a lookup based on a specific key! Maps are indexed by keys.</p>
<p>Can we put all types defined in Go for the key type? And for the value type?</p>
<div id="keys-types-allowed" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Keys: types allowed <a href="#keys-types-allowed"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>You cannot use any type for keys of a map. There is a restriction. The type MUST : “The comparison operators <span v-highlightjs><code class="go" v-pre style="display: inline">==</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">!=</code></span> must be <strong>fully</strong> defined for operands of the key type”<a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a> Which types are therefore <strong>excluded</strong> ?</p>
<ul>
<li><p>function</p></li>
<li><p>map</p></li>
<li><p>slice</p></li>
<li><p>array of function, map, or slice</p></li>
<li><p>struct type that contains fields of type function, map, or slice</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// FORBIDDEN: an array of slices
[3][]int

// FORBIDDEN : an array of functions
[3]func(http.ResponseWriter, *http.Request)

// FORBIDDEN: a type with a slice field
type Test struct {
    scores []int
}
//...</code></pre>
<div id="keys-must-be-distinct" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Keys must be distinct <a href="#keys-must-be-distinct"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The keys of a map must be <strong>distinct</strong>.</p>
<p>If we use an image, a map is like a corridor with locked doors. Behind each door, there is a value. The keys that can open the doors are unique (you can make copies of the keys, but the keys’ design stays the same). Each key opens a given door. There is a 1-1 relation between the keys and the doors.</p>
<div id="elements" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Elements <a href="#elements"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The elements are what you store on the map. For the elements, there are no restrictions concerning the type. You can store whatever you want. You can also store another map into a value.</p>
<p>For instance, an element can be a year, the score of a match, a type struct representing a user of an application...</p>
<div id="how-to-create-a-map" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> How to create a map <a href="#how-to-create-a-map"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="with-the-make-builtin" class="anchor"></div>
<h2 data-number="8.1"><span class="header-section-number">8.1</span> With the make builtin <a href="#with-the-make-builtin"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can use the make builtin to allocate and initialize a new map :</p>
<pre v-highlightjs><code class="go" v-pre >m:=make(map[string]int)</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">m</code></span> will be a value of type <span v-highlightjs><code class="go" v-pre style="display: inline">map[string]int</code></span>. This is called a map value, and internally it’s a pointer to a hash table. We will see in the next sections what is exactly a hash table, so do not worry now about it.</p>
<div id="with-the-map-literal-syntax" class="anchor"></div>
<h2 data-number="8.2"><span class="header-section-number">8.2</span> With the “map literal” syntax <a href="#with-the-map-literal-syntax"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>With the previous syntax, we initialize and allocate the map. But we do not fill it. We can fill it directly by using the map literal syntax:</p>
<pre v-highlightjs><code class="go" v-pre >worldCupWinners := map[int]string{
        1930: &quot;Uruguay&quot;,
        1934: &quot;Italy&quot;,
        1938: &quot;Italy&quot;,
        1950: &quot;Uruguay&quot;}
fmt.Println(worldCupWinners)
//map[1930:Uruguay 1934:Italy 1938:Italy 1950:Uruguay]</code></pre>
<p>In the previous code listing, we create a map named <span v-highlightjs><code class="go" v-pre style="display: inline">worldCupWinners</code></span>. This map is directly populated with four <strong>entries</strong>. The first four winners of the football world cup. The keys here are integers; they represent the years. The values are <span v-highlightjs><code class="go" v-pre style="display: inline">strings</code></span> that represents the country’s name that won the cup in the given year. In 1930 it was Uruguay that won the cup.</p>
<p>Please note that <strong>values can be repeated</strong>. The value Italy and Uruguay are repeated twice. It’s perfectly authorized.</p>
<p>Note also that <strong>after initializing a map, you can add new values to it</strong>. In our example, we can add another year to the map!</p>
<p>You can also use the map literal syntax to create an empty map.</p>
<pre v-highlightjs><code class="go" v-pre >a := map[int]string{}</code></pre>
<p>In the previous code listing, a is a map (initialized and allocated), but no key-value pairs are stored in it.</p>
<div id="what-is-a-hash-table" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> What is a hash table? <a href="#what-is-a-hash-table"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Here is a simplified view of how a hash table works. (the go implementation is slightly different) :</p>
<figure>
<b-img :src="require('@/assets/images/hash_table.png')" alt="Hash Table"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Hash Table</figcaption>
</figure>
<p>A hash table is composed of 3 elements :</p>
<ul>
<li><p><strong>A hash function</strong>. its role is to transform a key into a unique identifier. For instance, the key 1930 will be passed to the hash function, and it will return “4”.</p></li>
<li><p><strong>An indexed storage</strong> that is used to keep the values in memory. The storage is eventually organized in buckets. Each bucket can store a specific number of values.</p></li>
</ul>
<p>When we add a key-value pair to the hash table, the algorithm will go through the following steps :</p>
<ol type="1">
<li><p>From the <span v-highlightjs><code class="go" v-pre style="display: inline">key</code></span> get the return value of <span v-highlightjs><code class="go" v-pre style="display: inline">hash_function(key)</code></span> (we denote the return value <span v-highlightjs><code class="go" v-pre style="display: inline">h</code></span>). <span v-highlightjs><code class="go" v-pre style="display: inline">h</code></span> is the index where data is stored (for instance, 4)</p></li>
<li><p>Store the <span v-highlightjs><code class="go" v-pre style="display: inline">value</code></span> into the container at index <span v-highlightjs><code class="go" v-pre style="display: inline">h</code></span></p></li>
</ol>
<p>Retrieving a <span class="math inline">value</span> from a given <span class="math inline">key</span> will also make use of the hash function :</p>
<ol type="1">
<li><p>From the <span v-highlightjs><code class="go" v-pre style="display: inline">value</code></span> get the return value <span v-highlightjs><code class="go" v-pre style="display: inline">hash_function(key)</code></span>. It will return the container index.</p></li>
<li><p>Extract the data from the given container and return it to the user.</p></li>
</ol>
<div id="a-good-hash-function" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> A good hash function <a href="#a-good-hash-function"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A good hash function must have the following qualities :</p>
<ul>
<li><p><strong>Avoid collisions of hashes</strong> :</p>
<ul>
<li>if you pass the key <span v-highlightjs><code class="go" v-pre style="display: inline">1989</code></span> to the hash function, it will return, for instance <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span>.</li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> will be the index of the storage of the value linked to <span v-highlightjs><code class="go" v-pre style="display: inline">1989</code></span>.</p>
<ul>
<li><p>Imagine now that for <span v-highlightjs><code class="go" v-pre style="display: inline">1938</code></span> the hash function returns the same index <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> !</p></li>
<li><p>When you store something with the key <span v-highlightjs><code class="go" v-pre style="display: inline">1989</code></span> it will erase what is already stored for the key <span v-highlightjs><code class="go" v-pre style="display: inline">1938</code></span>.</p></li>
<li><p>Imagine the mess that such collisions can produce! For instance, the hash function MD5 can produce collisions. (for more information, read the article <b-link class="citation" data-cites="stevens2006fast" href="#stevens2006fast" >[@stevens2006fast]</b-link>)</p></li>
</ul></li>
<li><p><strong>Compute an index to get the location of the data</strong> in a limited amount of time. (the hash function must be time-efficient)</p></li>
<li><p><strong>The hash produced must be stable in time.</strong> The key should produce the same hash at each call.</p></li>
</ul>
<div id="hash-table-time-complexity" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Hash table time complexity <a href="#hash-table-time-complexity"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>An algorithm’s complexity is the amount of resources it takes to run it on a machine.<a href="#fn3" class="footnote-ref" id="fnref3" role="doc-noteref"><sup>3</sup></a></p></li>
<li><p>The time complexity is a kind of complexity; it designates the amount of computer time needed to run a program<a href="#fn4" class="footnote-ref" id="fnref4" role="doc-noteref"><sup>4</sup></a></p></li>
</ul>
<p>Time complexity will depend on the hash table’s implementation, but keep in mind that time complexity is very low for searching a value and inserting a new key-value pair.</p>
<p>The following time complexity applies in general for hash tables :</p>
<ul>
<li><p>Insertion : <strong>O(1)</strong></p></li>
<li><p>Search : <strong>O(1)</strong></p></li>
</ul>
<p>Search and insertion will take the same number of basic operations on a map containing three elements and a map containing 3 million elements!</p>
<p>We say that it’s a <strong>constant-time algorithm</strong>. We also say that it’s <strong>order 1.</strong>I used here the Big-O notation<a href="#fn5" class="footnote-ref" id="fnref5" role="doc-noteref"><sup>5</sup></a>.</p>
<div id="go-internals-the-hash-table-implementation" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Go internals: The hash table implementation <a href="#go-internals-the-hash-table-implementation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>This is an overview of how maps are implemented in Go. The internal implementation might change over time.</p>
<p>The source code is located in the runtime package (runtime/map.go).</p>
<ul>
<li><p>A Go map is an array of “<strong>buckets</strong>”</p></li>
<li><p>A <strong>bucket</strong> contains a maximum number of 8 key/element pairs (also called 8 <strong>entries</strong>).</p></li>
<li><p>Each bucket is identified by a number (an id).</p></li>
</ul>
<div id="lookup-an-element" class="anchor"></div>
<h4 data-number="11.0.0.1"><span class="header-section-number">11.0.0.1</span> Lookup an element <a href="#lookup-an-element"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>To find an element into a map, the user will give a <strong>key</strong>.</p>
<ul>
<li>Key: 1930</li>
</ul></li>
<li><p>The key will be passed to the <strong>hash function</strong> it will return an <strong>hash value</strong>(which is an integer)</p></li>
<li><p>This <strong>hash value</strong> <u>contains</u> the bucket id. The hash function does not directly return the id of the bucket, the return value <span v-highlightjs><code class="go" v-pre style="display: inline">h</code></span> has to be transformed to get the bucket id.</p>
<ul>
<li>Bucket id = 3</li>
</ul></li>
<li><p>Knowing the bucket id, the next step is to find the correct entry in the bucket. This is done by comparing the key given to all the bucket keys.</p>
<ul>
<li>Key : “1930”. Go will iterate through the keys of the bucket and return the corresponding element</li>
</ul></li>
</ul>
<div id="insert-an-element" class="anchor"></div>
<h4 data-number="11.0.0.2"><span class="header-section-number">11.0.0.2</span> Insert an element <a href="#insert-an-element"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>The user provides the <strong>key</strong> and the <strong>element</strong> value</p>
<ul>
<li>Ex: <strong>Key</strong> : “1930” - <strong>Element</strong> : “Uruguay”</li>
</ul></li>
<li><p>The <strong>key</strong> is passed to the <strong>hash function.</strong>The <strong>hash function</strong> will return the <strong>hash.</strong></p></li>
<li><p>From the hash, we will retrieve the bucket id.</p></li>
<li><p>Go will then iterate over the bucket elements to find a place to store the key and the element.</p>
<ul>
<li>When the key is already present, Go will override the element’s value.</li>
</ul></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/go_hashmap.png')" alt="Go Hashmap implementation"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Go Hashmap implementation</figcaption>
</figure>
<div id="example-usage-setup" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Example usage setup <a href="#example-usage-setup"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In this section, we will take a look at the most common operations you can do on a map. To do that, we will use an example.</p>
<div id="the-example-application" class="anchor"></div>
<h4 data-number="12.0.0.1"><span class="header-section-number">12.0.0.1</span> The example application <a href="#the-example-application"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>You are asked to build an application for the HR department</p></li>
<li><p>In the alpha version, we will load the list of employees via a CSV file</p></li>
<li><p>The users will need to query employees by their employeeId (composed of letters and numbers)</p>
<ul>
<li>Ex: V45657 <strong>,</strong>V45658...</li>
</ul></li>
</ul>
<p>Here is an excerpt of the CSV file :</p>
<pre v-highlightjs><code class="go" v-pre >employeeId,employeeName,genre,position
V45657,John Ollivero,M,CEO
V45658,Frane Elindo,F,CTO
V6555,Walter Van Der Bolstenberg,M,Sales Manager</code></pre>
<div id="why-maps" class="anchor"></div>
<h4 data-number="12.0.0.2"><span class="header-section-number">12.0.0.2</span> Why maps? <a href="#why-maps"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The users will <strong>query</strong> an employee based on its unique Id.</p>
<ul>
<li><p>We will query employees based on a unique key</p></li>
<li><p>This id is not an integer; we can use a slice or a map.</p></li>
</ul>
<p>We will use a map, and we will create an <span v-highlightjs><code class="go" v-pre style="display: inline">employee</code></span> type.</p>
<ul>
<li><p>Keys : the employeeId =&gt; string</p></li>
<li><p>Elements : values of type <span v-highlightjs><code class="go" v-pre style="display: inline">employee</code></span></p></li>
</ul>
<div id="reading-data-from-csv" class="anchor"></div>
<h4 data-number="12.0.0.3"><span class="header-section-number">12.0.0.3</span> Reading data from CSV <a href="#reading-data-from-csv"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Let’s build the first part of the script (to read the data into the file)</p>
<pre v-highlightjs><code class="go" v-pre >// maps/reading-csv/main.go
package main

import (
    &quot;encoding/csv&quot;
    &quot;fmt&quot;
    &quot;io&quot;
    &quot;log&quot;
    &quot;os&quot;
)

func main() {
    file, err := os.Open(&quot;/Users/maximilienandile/Documents/DEV/goBook/maps/usages/employees.csv&quot;)
    if err != nil {
        log.Fatalf(&quot;impossible to open file %s&quot;, err)
    }

    defer file.Close()

    r := csv.NewReader(file)
    for {
        record, err := r.Read()
        if err == io.EOF {
            break
        }
        if err != nil {
            log.Fatal(err)
        }
        fmt.Println(record)
    }
}</code></pre>
<p>The first step is to open the file <u>employees.csv</u>.</p>
<p>We are using the standard library <span v-highlightjs><code class="go" v-pre style="display: inline">os</code></span>. Like always, we check for errors and return if they are some (but before returning, we are printing an error message).</p>
<p>After that, we use the <span v-highlightjs><code class="go" v-pre style="display: inline">csv</code></span> package. We create a reader with <span v-highlightjs><code class="go" v-pre style="display: inline">r := csv.NewReader(file)</code></span>, that will allow us to read the file line by line. We initialize a line counter to keep track of the line number.</p>
<p>Then we start the reading with the for loop. We read a new line with the <span v-highlightjs><code class="go" v-pre style="display: inline">record, err := r.Read()</code></span>. The record variable is a slice of strings (<span v-highlightjs><code class="go" v-pre style="display: inline">[]string</code></span>). Next, we check for errors, with the subtility that <span v-highlightjs><code class="go" v-pre style="display: inline">r.Read()</code></span> will populate err with <span v-highlightjs><code class="go" v-pre style="display: inline">io.EOF</code></span> when it has reached the end of the file. We have to check that before checking that <span v-highlightjs><code class="go" v-pre style="display: inline">err</code></span> is not <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span>. If we have reached the end of the file, we will stop the for loop with the keyword <span v-highlightjs><code class="go" v-pre style="display: inline">break</code></span><strong>.</strong> After that, we can finally read the data of the file.</p>
<p>The variable record will return, for instance <span v-highlightjs><code class="go" v-pre style="display: inline">[V45657 John Ollivero M CEO]</code></span><strong>.</strong></p>
<p>The data is stored in a slice, and at the index 0, we will find the <span v-highlightjs><code class="go" v-pre style="display: inline">employeeID</code></span>, at index one the name, at index two the genre, and the position at index 3 !</p>
<p>We also have to define our type employee :</p>
<pre v-highlightjs><code class="go" v-pre >type employee struct {
    name     string
    genre    string
    position string
}</code></pre>
<p>The preparatory work is done let’s jump to the map creation and usage</p>
<div id="initialize-and-add-a-keyelement-pair" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> Initialize and add a key/element pair <a href="#initialize-and-add-a-keyelement-pair"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<pre v-highlightjs><code class="go" v-pre >// initialize and allocate a new map
employees := make(map[string]employee)
// ...
employee := employee{
            name:     record[1],
            genre:    record[2],
            position: record[3]}
// Add a new entry to the map
employees[employeeId] = employee</code></pre>
<p>To add a pair composed of a <span v-highlightjs><code class="go" v-pre style="display: inline">key</code></span> and a <span v-highlightjs><code class="go" v-pre style="display: inline">element</code></span> simply use the following syntax : <span class="math display">m[\text{key}]=\text{value}</span></p>
<div id="retrieve-a-value" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Retrieve a value <a href="#retrieve-a-value"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To get an element from a map you <strong>have to know it’s key.</strong> They are two different ways to do it :</p>
<div id="short-syntax" class="anchor"></div>
<h3 data-number="14.0.1"><span class="header-section-number">14.0.1</span> Short syntax <a href="#short-syntax"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Imagine that you are looking for the data related to employee number 3.</p>
<p>You will retrieve the value (a struct employee) by calling :</p>
<pre v-highlightjs><code class="go" v-pre >walter := employees[&quot;V6555&quot;]</code></pre>
<p>Here we assign to the variable walter the value contained into the map employeeMap with the key <strong>V6555.</strong></p>
<div id="when-the-key-does-not-exists" class="anchor"></div>
<h3 data-number="14.0.2"><span class="header-section-number">14.0.2</span> When the key does not exists? <a href="#when-the-key-does-not-exists"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>But what if the value does not exist? Will you make your program panic? Let’s take the risk :</p>
<pre v-highlightjs><code class="go" v-pre >// when there is no such pair
ghost := employees[&quot;ABC55555&quot;]
fmt.Println(ghost)
//{  }
fmt.Println(reflect.TypeOf(ghost))
// main.employee</code></pre>
<p>Here we attempt to get the value of the employee that has the id <span v-highlightjs><code class="go" v-pre style="display: inline">"ABC55555"</code></span>.</p>
<p>The key does not exist on the map. <strong>Go will return the null value of the type.</strong></p>
<div id="warning-be-very-careful-with-this-syntax-because-it-can-lead-to-errors." class="anchor"></div>
<h4 data-number="14.0.2.1"><span class="header-section-number">14.0.2.1</span> Warning! Be very careful with this syntax because it can lead to errors. <a href="#warning-be-very-careful-with-this-syntax-because-it-can-lead-to-errors."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>In the case of our HR software example, imagine that after loading the data into the map, you propose to your users some kind of interface where they can see the data of an employee in function of its id. What if the user types the id “100”. You implement a function that will return an employee given a specific key. You will return an empty object employee.</p>
<p>We can guess that the employee does not exist, but it’s not 100% sure. Those empty fields can also come from a corrupted file.</p>
<p>That’s why Go creators have provided a more clever way to retrieve an entry in a map.</p>
<div id="two-values-assignment" class="anchor"></div>
<h3 data-number="14.0.3"><span class="header-section-number">14.0.3</span> Two values assignment <a href="#two-values-assignment"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The alternative syntax is the following :</p>
<pre v-highlightjs><code class="go" v-pre >v, ok := myMap[k]</code></pre>
<p>The variable <span v-highlightjs><code class="go" v-pre style="display: inline">ok</code></span> is a boolean that will hold the indication of the existence of the key-value pair in the map:</p>
<div class="list">
<p>the key-value pair exists in the map, <strong>v</strong> is populated with the value at key <strong>k</strong></p>
<p>the key-value pair does not exist, <strong>v</strong> is populated with the null value of type <strong>valueType</strong></p>
</div>
<p>Often you will see this idiom :</p>
<pre v-highlightjs><code class="go" v-pre >// lookup with two values assignment
employeeABC2, ok := employees[&quot;ABC2&quot;]
if ok {
    // the key-element pair exists in the map
    fmt.Println(employeeABC2)
} else {
    fmt.Printf(&quot;No employee with ID &#39;ABC2&#39;&quot;)
}</code></pre>
<p>It’s possible to ignore the value if you just want to test the presence of a key into the map :</p>
<pre v-highlightjs><code class="go" v-pre >// ignore the value retrieved
_, ok := employees[&quot;ABC3&quot;]
if ok {
    // the key-element pair exists in the map
} else {
    fmt.Printf(&quot;No employee with ID &#39;ABC3&#39;&quot;)
}</code></pre>
<p>In the previous example, we are telling the compiler that we do not need the value retrieved by using the underscore (<strong>_</strong>) character in the assignation.</p>
<p>There is a shorter way to make the same operation :</p>
<pre v-highlightjs><code class="go" v-pre >// shorter code
if _, ok := employees[&quot;ABC4&quot;]; ok {
    // the key-element pair exists in the map
} else {
    fmt.Println(&quot;No employee with ID &#39;ABC4&#39;&quot;)
}</code></pre>
<p>The two values assignment and the ok value check are done in one line!</p>
<div id="warning-map-values-are-not-addressable" class="anchor"></div>
<h3 data-number="14.0.4"><span class="header-section-number">14.0.4</span> Warning! Map values are not addressable <a href="#warning-map-values-are-not-addressable"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Values retrieved from a map are not addressable. You cannot print the memory address of a map value.</p>
<p>For instance, the following code :</p>
<pre v-highlightjs><code class="go" v-pre >fmt.Printf(&quot;address of the100 %p&quot;, &amp;employeeMap[100])</code></pre>
<p>Will result in a compiler error :</p>
<pre v-highlightjs><code class="go" v-pre >./main.go:66:14: cannot take the address of employeeMap[100]</code></pre>
<p>Why this behavior? Because Go can change the memory location of a key-value pair when it adds a new key-value pair. Go will do this under the hood to keep the complexity of retrieving a key-value pair at a constant level. As a consequence, the address can become invalid. Go prefers to forbid the access of a possible invalid address than letting you try your chance. This is a good thing !</p>
<div id="memory-usage-consideration" class="anchor"></div>
<h3 data-number="14.0.5"><span class="header-section-number">14.0.5</span> Memory Usage consideration <a href="#memory-usage-consideration"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Please be aware that when you keep a value extracted from a map (and if you do not use the map anymore), Go will keep the whole map in memory.</p>
<p>The garbage collector will not do its job and remove the unused memory.</p>
<div id="delete-an-entry" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> Delete an entry <a href="#delete-an-entry"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>You can delete a key-value pair by using the <span v-highlightjs><code class="go" v-pre style="display: inline">delete</code></span> built-in function. The function has the following header :</p>
<pre v-highlightjs><code class="go" v-pre >func delete(m map[Type]Type1, key Type)</code></pre>
<p>It takes:</p>
<ul>
<li><p>a map as first argument</p></li>
<li><p>a <strong>key</strong></p></li>
</ul>
<p>The second argument is the <strong>key</strong> of the entry, you want to destroy.</p>
<ul>
<li><p>If the entry does not exist in the map it will not panic (and it will compile).</p></li>
<li><p>If you use for the second argument a different type than the key type, the program will not compile.</p></li>
</ul>
<p>Let’s take an example:</p>
<p>If you want to delete the entry with index two from the map <span v-highlightjs><code class="go" v-pre style="display: inline">employees</code></span> you can use the following code :</p>
<pre v-highlightjs><code class="go" v-pre >delete(employees, &quot;ABC4&quot;)</code></pre>
<p>The entry with the key <span v-highlightjs><code class="go" v-pre style="display: inline">"ABC4"</code></span> will be destroyed from memory if it exists.</p>
<div id="length" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> Length <a href="#length"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>You can retrieve the number of entries in the map with the built-in <strong>len :</strong></p>
<pre v-highlightjs><code class="go" v-pre >fmt.Println(len(employees))
// 3
// There are three entries into the map

// remove entry with index 2
delete(employees, &quot;V6555&quot;)

fmt.Println(len(employeeMap))
// 2
// There are two entries into the map</code></pre>
<div id="iterate-over-a-map" class="anchor"></div>
<h1 data-number="17"><span class="header-section-number">17</span> Iterate over a map <a href="#iterate-over-a-map"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>You can use the for loop with a range clause to iterate over all entries of a map :</p>
<pre v-highlightjs><code class="go" v-pre >for k, v := range employeeMap {
    fmt.Printf(&quot;Key: %s - Value: %s\n&quot;, k, v)
}
// Key: V6555 - Value: {Walter Van Der Bolstenberg M Sales Manager}
// Key: V45657 - Value: {John Ollivero M CEO}
// Key: V45658 - Value: {Frane Elindo F CTO}</code></pre>
<div id="do-not-rely-on-iteration-order" class="anchor"></div>
<h3 data-number="17.0.1"><span class="header-section-number">17.0.1</span> Do not rely on iteration order! <a href="#do-not-rely-on-iteration-order"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Note that this code snippet will return the elements, not in the insertion order.</p>
<p>This is because <strong>order is not assured</strong>. If we try to run a second time the same script, we might have the following result :</p>
<pre v-highlightjs><code class="go" v-pre >Key: V45657 - Value: {John Ollivero M CEO}
Key: V45658 - Value: {Frane Elindo F CTO}
Key: V6555 - Value: {Walter Van Der Bolstenberg M Sales Manager}</code></pre>
<p>Please keep this in mind as <strong>it can be a source of errors.</strong></p>
<div id="a-solution-to-the-order-problem" class="anchor"></div>
<h3 data-number="17.0.2"><span class="header-section-number">17.0.2</span> A solution to the order problem <a href="#a-solution-to-the-order-problem"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>You can solve this ordering problem by using another variable to store the insertion order. If the order is important to you, you can use this solution :</p>
<pre v-highlightjs><code class="go" v-pre >order := []string{}
order = append(order, employeeID)
employeeMap[employeeID] = employee</code></pre>
<p>Here we create a slice <strong>order.</strong> This slice will store the keys in the insertion order into the map. So each time we add an entry to the map, we add the key to the slice by calling <span v-highlightjs><code class="go" v-pre style="display: inline">order = append(order, employeeID)</code></span>.</p>
<p>This way, we can get the entries in the order of insertion :</p>
<pre v-highlightjs><code class="go" v-pre >for _,k := range order {
    fmt.Printf(&quot;Key: %s - Value: %s\n&quot;, k, employees[k])
}</code></pre>
<p>We iterate over the slice order to get the keys, and then we retrieve the entry value by calling <span v-highlightjs><code class="go" v-pre style="display: inline">employees[k]</code></span>, where <span v-highlightjs><code class="go" v-pre style="display: inline">k</code></span> represents a key of the map <span v-highlightjs><code class="go" v-pre style="display: inline">employees</code></span>.</p>
<div id="two-dimensional-maps-map-of-maps" class="anchor"></div>
<h1 data-number="18"><span class="header-section-number">18</span> Two-dimensional maps (map of maps) <a href="#two-dimensional-maps-map-of-maps"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In our previous example, we wanted to store data with the structure :<span v-highlightjs><code class="go" v-pre style="display: inline">enployeeID</code></span> =&gt; <span v-highlightjs><code class="go" v-pre style="display: inline">enployeeData</code></span></p>
<p>The key is the <span v-highlightjs><code class="go" v-pre style="display: inline">enployeeID</code></span> and the value is a structtype <span v-highlightjs><code class="go" v-pre style="display: inline">employee</code></span>. But imagine that we do not want to store a struct but another map instead :</p>
<figure>
<b-img :src="require('@/assets/images/2d_map.png')" alt="Two-dimensional map[fig:Two-dimensional-map]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Two-dimensional map<span id="fig:Two-dimensional-map" label="fig:Two-dimensional-map">[fig:Two-dimensional-map]</span></figcaption>
</figure>
<p>In the figure <a href="#fig:Two-dimensional-map" data-reference-type="ref" data-reference="fig:Two-dimensional-map">2</a> there are two maps. The second map is of type<span v-highlightjs><code class="go" v-pre style="display: inline">map[string]string</code></span>. We store as keys “Name”, “Position” and “Genre” and the values are the corresponding employee data. The first map is of type<span v-highlightjs><code class="go" v-pre style="display: inline">map[int]map[string]string</code></span><strong>.</strong> The type notation is a little bit confusing, but when you look at it closely it makes sense :</p>
<figure>
<b-img :src="require('@/assets/images/map_inside_map.png')" alt="The value of the map is another map"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The value of the map is another map</figcaption>
</figure>
<p>The second map is the inner map. It is the value of the first map. Each entry of this type has an integer key and for value a <strong>map[string]string.</strong></p>
<p>Two-dimensional maps are, in my opinion, too complicated. You might better use a map with a struct value.</p>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="19"><span class="header-section-number">19</span> Test Yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="19.1"><span class="header-section-number">19.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>How to check if a key/element pair is in a map?</p></li>
<li><p>How are Go maps implemented internally?</p></li>
<li><p>Which types are forbidden for map keys?</p></li>
<li><p>Why is it forbidden to use some types for keys of a map?</p></li>
<li><p>When you iterate over a map, then the runtime will return the keys and the elements in the order you inserted them. True or False ?</p></li>
<li><p>How to remove a key/element pair from a map?</p></li>
<li><p>How to get the number of key/element pairs in a map?</p></li>
<li><p>How to iterate over a map?</p></li>
<li><p>If a map M does not contain the key K, what will return M[K]?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="19.2"><span class="header-section-number">19.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>How to check if a key/element pair is in a map?</p>
<ol type="1">
<li>Let’s say that you want to check if there is a key/element pair with a key equal to <span v-highlightjs><code class="go" v-pre style="display: inline">102</code></span> in a map rooms: <span v-highlightjs><code class="go" v-pre style="display: inline">room, ok = rooms[102];</code></span>. When ok is true, the pair exists.</li>
</ol></li>
<li><p>How are Go maps implemented internally?</p>
<ol type="1">
<li>Internally, Go maps are hash tables.</li>
</ol></li>
<li><p>Which types are forbidden for map keys?</p>
<ol type="1">
<li><p>functions, slices, maps</p></li>
<li><p>Any array composed of the previous types</p></li>
<li><p>Any type composed of at least one of those types</p></li>
</ol></li>
<li><p>Why is it forbidden to use some types for keys of a map?</p>
<ol type="1">
<li>Because the comparison operators <span v-highlightjs><code class="go" v-pre style="display: inline">==</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">=* are not fully defined for those types. Go needs to be able to compare keys in its internal implementation. \end{enumerate} \item When you iterate over a map, then the runtime will return the keys and the elements in the order you inserted them. True or False ? \begin{enumerate} \item False. A map is an unordered collection. Go will \textbf{not} keep the memory of the insertion order. You will have to save it yourself if you need it. \end{enumerate} \item How to remove a key/element pair from a map?  \lstinline{delete(employees, "ABC4")} \begin{enumerate} \item When the element is not found, nothing will happen</code></span></li>
</ol></li>
<li><p>How to get the number of key/element pairs in a map?</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">len(myMap)</code></span></p></li>
<li><p>How to iterate over a mapTakeaways?</p>
<ol type="1">
<li>With a for loop : <span v-highlightjs><code class="go" v-pre style="display: inline">for k, v := range employees</code></span></li>
</ol></li>
<li><p>If a map <span v-highlightjs><code class="go" v-pre style="display: inline">M</code></span> does not contain the key <span v-highlightjs><code class="go" v-pre style="display: inline">K</code></span> , what will return <span v-highlightjs><code class="go" v-pre style="display: inline">M[K]</code></span> ?</p>
<ol type="1">
<li><p>It will return the zero value of the element type</p></li>
<li><p>If the element is an int it will return 0 for instance.</p></li>
</ol></li>
</ol>
<div id="key-takeways" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="20"><span class="header-section-number">20</span> Key Takeways <a href="#key-takeways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>A map is an <strong>unordered</strong> collection of elements (values) of type V that are indexed by unique keys of type K</p></li>
<li><p>Map types are denoted like this : <span v-highlightjs><code class="go" v-pre style="display: inline">map[K]V</code></span></p></li>
<li><p>An element inside a map is called a map entry or a key-value pair.</p></li>
<li><p>To initialize a map, you can use the following syntaxes :</p>
<pre v-highlightjs><code class="go" v-pre >m := make(map[string]uint8)

m := map[string]uint8{ &quot;This is the key&quot;:42}</code></pre></li>
<li><p>The zero value of the map type is <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span>.</p>
<pre v-highlightjs><code class="go" v-pre >var m map[string]uint8
log.Println(m)</code></pre>
<ul>
<li>Will output <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span></li>
</ul></li>
<li><p>To insert an element into a map, you can use the following syntax : <span v-highlightjs><code class="go" v-pre style="display: inline">m2[ "myNewKey"] = "the value"</code></span></p></li>
<li><p><strong>Important</strong> : a map should be initialized before used</p>
<ul>
<li>The following program will cause a panic :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >var m map[string]uint8
m[&quot;test&quot;] = 122

panic: assignment to entry in nil map</code></pre></li>
<li><p>To retrieve an element in a map, you can use the following syntax</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">m := make(map[string]uint8)// fill the mapvalueRetrieved := m[ "myNewKey"]</code></span></p>
<ul>
<li><p>When no value is found, the variable <span v-highlightjs><code class="go" v-pre style="display: inline">valueRetrieved</code></span> will be equal to the <strong>zero value</strong> of the map value type.</p>
<ul>
<li>Here <span v-highlightjs><code class="go" v-pre style="display: inline">valueRetrieved</code></span> will be equal to 0 (zero value of type <span v-highlightjs><code class="go" v-pre style="display: inline">uint8</code></span>)</li>
</ul></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >m := make(map[string]uint8)
// fill the map
valueRetrieved, ok := m[ &quot;myNewKey&quot;]
if ok {
    // found an entry in the map with the key &quot;myNewKey&quot;

} else {
    // not found :(

}</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ok</code></span> is a boolean which is equal to true if an entry with that key exists in the map</p></li>
<li><p>You can iterate over a map with a for loop (with range clause)</p>
<ul>
<li><p>Warning: the order of insertion may not be used (it is not guaranteed)!</p></li>
<li><p>To keep in memory the order of insertion in the map, create a slice and append each key to it</p></li>
<li><p>Then you can iterate over the slice and fetch each value in the order of insertion.</p></li>
</ul></li>
<li><p>Insertion and lookup in a map are very <strong>quick</strong>, even if the map has many entries.</p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>https://golang.org/ref/spec#Map_types<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>Go Specs https://golang.org/ref/spec#Map_types<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn3" role="doc-endnote"><p>https://en.wikipedia.org/wiki/Computational_complexity<a href="#fnref3" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn4" role="doc-endnote"><p>https://en.wikipedia.org/wiki/Time_complexity<a href="#fnref4" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn5" role="doc-endnote"><p>You can find more info about this notation on this Wikipedia article: https://en.wikipedia.org/wiki/Big_O_notation<a href="#fnref5" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap22Maps"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap21Slices'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Slices</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap23Errors'}">
									<p><u><small>Next</small></u></p>
									<p><small>Errors</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Maps - Practical Go Lessons"
const description = "We will detail how maps are working. A map is an unordered collection of elements of type T that are indexed by unique keys of type U"

export default {
  name: "Chap22Maps",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

mounted() {
    const mathElements = window.document.getElementsByClassName("math");
    const macros = [];
    for (var i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;
      if (mathElements[i].tagName === "SPAN") {
 		window.katex.render(texText.data, mathElements[i], {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: true,
          macros: macros,
          fleqn: false
        });
      }
    }
  },

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
